import React from "react";
import { Link } from "gatsby";
import NewsItem from "./home-news-item";
import Button from "./button";
import { cn } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/home-news.module.css";

const News = (props) => {

  const {
    heading,
    news,
    button
  } = props;

  return (
    <div className={styles.root} id="latest-news">
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-sm">
            {heading && (<h2 className={cn("h1", styles.heading)}>{heading}</h2>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-xl">
            {(news && news.length) ? (
              <div className={styles.newsList}>
                {news.map(({ news, _key }) => {
                  return (
                    <div key={_key} className={styles.item}>
                      <NewsItem news={news} />
                    </div>
                  );
                })}
              </div>
            ) : 
              null
            }

            {button.blank ? (
              <a href={button.url} className={cn("btn", button.primaryButton && "primary", button.noArrow && "center")} target="_blank" rel="noreferrer">
                {button.text}
                {!button.noArrow && (<Icon symbol="arrow-right" />)}
              </a>
            ) : (
              <Link to={button.url} className={cn("btn", button.primaryButton && "primary", button.noArrow && "center")}>
                {button.text}
                {!button.noArrow && (<Icon symbol="arrow-right" />)}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
