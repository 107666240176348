import React, { useLayoutEffect } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import About from "../components/home-about";
import Links from "../components/home-links";

import * as styles from "../styles/components/home-bottom.module.css";

const HomeBottom = (props) => {

  const {
    about,
    links
  } = props;

  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline();

  useLayoutEffect(() => {
    setTimeout(() => {
      tl.to('#home-bottom-bg', {
        opacity: 1,
        ease: "none",
      });

      tl.to('#home-bottom-bg', {
        scrollTrigger: {
          trigger: 'body',
          start: "top bottom", // the default values
          end: "bottom top",
          scrub: true
        },
        yPercent: 150,
        ease: 'none',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.bg} id="home-bottom-bg">
          <img src="/images/feature_man-dark.png" alt="" />
        </div>

        {about && (<About {...about} />)}

        {links && (<Links {...links} />)}
      </Container>
    </div>
  );
}

export default HomeBottom;
