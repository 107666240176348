import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import PortableText from "./portableText";
import { Link } from 'react-scroll';

import * as styles from "../styles/components/home-page-header.module.css";

const PageHeader = (props) => {
  const {
    heading,
    _rawContent
  } = props;

  // Get viewport size
  const hasWindow = typeof window !== 'undefined';
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, []);
    return windowDimensions;
  }
  const { width } = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6 offset-md-6">
          <div className="content-md">
            {heading && (<h1 className={styles.heading}>{heading}</h1>)}
            {_rawContent && (<PortableText blocks={_rawContent} />)}
            <Link 
              to="latest-news"
              spy={false}
              smooth={true}
              hashSpy={false}
              offset={width >= 1200 ? -324 : width >= 960 && width < 1200 ? -244 : -218}
              duration={800}
              delay={0}
              isDynamic={true}
              ignoreCancelEvents={false}
              spyThrottle={0}
              className={styles.btnScroll}
            >
              <Icon symbol="scroll" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
