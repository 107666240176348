import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout-dark";

import HomeTop from "../components/home-top";
import CounterBlock from "../components/counter-block";
import HomeBottom from "../components/home-bottom";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    home: sanityHome {
      pageHeader {
        heading
        _rawContent
      }
      ourApproach {
        heading
        _rawContent
      }
      news {
        heading
        button {
          blank
          primaryButton
          noArrow
          text
          url
        }
        news {
          _key
          news {
            publishedOn
            title
            category {
              slug {
                current
              }
            }
            slug {
              current
            }
            externalLink {
              url
            }
            documentLink {
              document {
                asset {
                  url
                  originalFilename
                }
              }
            }
          }
        }
      }
      counterBlock {
        content {
          heading
          _rawContent
        }
        number
        goalNumber
        goalText
        bg
      }
      about {
        heading
        _rawContent
      }
      links {
        heading
        links {
          _key
          link {
            blank
            text
            url
          }
          description
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Seo
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      {(home.pageHeader || home.news) && <HomeTop pageHeader={home.pageHeader} ourApproach={home.ourApproach} news={home.news} />}

      {home.counterBlock && <CounterBlock {...home.counterBlock} />}

      {(home.about || home.links) && <HomeBottom about={home.about} links={home.links} />}
    </Layout>
  );
};

export default IndexPage;
