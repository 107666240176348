import React from "react";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import { format } from "date-fns";
import { Link } from "gatsby";
import { getCatUrl } from "../lib/helpers";
import { getNewsUrl } from "../lib/helpers";

import * as styles from "../styles/components/home-news-item.module.css";

const NewsItem = (props) => {

  const {
    news
  } = props;

  return (
    <div className={styles.root}>
      {news.publishedOn && (
        <div className={styles.meta}><span className={styles.date}>{format(new Date(news.publishedOn).setDate(new Date(news.publishedOn).getDate() + 1), "MM/dd/yy")}</span></div>
      )}

      <h3 className={cn("h2-lg", styles.title)}>
        {news.slug ? (
          <Link to={getNewsUrl(news.category, news.slug)}>{news.title}</Link>
        ) : (news.externalLink && news.externalLink.url) ? (
              <a href={news.externalLink.url} target="_blank" rel="noreferrer">{news.title}</a>
            ) : (news.documentLink && news.documentLink.document) ? (
                  <a href={news.documentLink.document.asset.url + "/" + news.documentLink.document.asset.originalFilename} target="_blank" rel="noreferrer">{news.title}</a>
                ) : null
        }
      </h3>
    </div>
  );
}

export default NewsItem;
