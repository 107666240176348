import React, { useLayoutEffect } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import PageHeader from "../components/home-page-header";
import OurApproach from "../components/home-our-approach";
import News from "../components/home-news";

import * as styles from "../styles/components/home-top.module.css";

const HomeTop = (props) => {

  const {
    pageHeader,
    ourApproach,
    news
  } = props;

  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline();

  useLayoutEffect(() => {
    setTimeout(() => {
      tl.to('#home-top-bg', {
        opacity: 1,
        ease: "none",
      });

      tl.to('#home-top-bg', {
        scrollTrigger: {
          trigger: 'body',
          start: "top bottom", // the default values
          end: "bottom top",
          scrub: true
        },
        yPercent: 150,
        ease: 'none',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.bg} id="home-top-bg">
          <img src="/images/feature_woman-dark.png" alt="" />
        </div>

        {pageHeader && (<PageHeader {...pageHeader} />)}

        {ourApproach && (<OurApproach {...ourApproach} />)}

        {news && (<News {...news} />)}
      </Container>
    </div>
  );
}

export default HomeTop;
