import React from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import Icon from "./icon";

import * as styles from "../styles/components/home-links.module.css";

const Links = (props) => {
  const {
    heading,
    links
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-sm">
            {heading && (<h2 className={cn("h1", styles.heading)}>{heading}</h2>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg">
            {(links && links.length) ? (
              <ul className={styles.linkList}>
                {links.map(item => {
                  return (
                    <li className={styles.item} key={item._key}>
                      {item.link.blank ? (
                        <a href={item.link.url} target="_blank" rel="noreferrer" className={cn("link", styles.link)}>{item.link.text}<Icon symbol="external" /></a>
                      ) : (
                        <Link to={item.link.url} className={cn("link", styles.link)}>{item.link.text}</Link>
                      )}
                      {item.description && <p>{item.description}</p>}
                    </li>
                  );
                })}
              </ul>
            ) : 
              null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;
